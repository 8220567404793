import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.clone()
    this.preload()
  }

  clone() {
    const backgroundEl = this.context.scope.element
    const blurredEl    = document.createElement('div');
    blurredEl.classList.add('background', 'blurred')
    blurredEl.setAttribute('style', backgroundEl.getAttribute('style'))
    backgroundEl.appendChild(blurredEl)
  }

  preload() {
    const backgroundEl = this.context.scope.element
    const image = new Image()
    const image_url = this.data.get('image')
    image.src = image_url
    image.onload = function() {
      this.remove()
      backgroundEl.setAttribute('style', "background-image: url("+image_url+")")
      backgroundEl.classList.add('loaded')
    }
  }
}
