import { Controller } from "stimulus"
import TinyGesture from 'tinygesture'

export default class extends Controller {
  static targets = [ 'phrase' ]

  connect() {
    this.redraw()
    this.watchGestures()
  }

  watchGestures() {
    const body = document.body
    window.gesture = new TinyGesture(body)
    gesture.on('swiperight', event => {
      this.prev()
    })
    gesture.on('swipeleft', event => {
      this.next()
    })
  }

  disconnect() {
    window.gesture.destroy()
  }

  redraw() {
    this.cancel()

    this.phraseTargets.forEach((item, i) => {
      item.style.width = ''
      item.style.width = item.offsetWidth + 'px'
    })

    const bodyWidth = document.body.offsetWidth
    const bodyHeight = document.body.offsetHeight
    this.controllerElement.style.left = (bodyWidth - this.controllerElement.offsetWidth) / 2 + 'px'
    this.controllerElement.style.top = (bodyHeight - this.controllerElement.offsetHeight) / 2 + 'px'
    this.controllerElement.classList.add('reveal')
    this.showCurrentBuzzword()
  }

  select(e) {
    const el = e.currentTarget
    const phrase = el.parentElement
    if (phrase === this.phraseTargets[this.active]) {
      return this.cancel()
    }
    this.phraseTargets.forEach((item, i) => {
      if (phrase == item) {
        this.active = i
      }
    })
  }

  next() {
    if (typeof(this.active) !== 'number') {
      this.active = this.phraseTargets.length - 1
    } else if (this.active >= this.phraseTargets.length - 1) {
      this.active = 0
    } else {
      this.active = this.active + 1
    }
  }

  prev() {
    if (typeof(this.active) !== 'number') {
      this.active = 0
    } else if (this.active === 0) {
      this.active = this.phraseTargets.length - 1
    } else {
      this.active = this.active - 1
    }
  }

  showCurrentBuzzword() {
    if (this.active != null) {
      const activeTarget = this.phraseTargets[this.active]
      if (activeTarget) {
        activeTarget.classList.add('active')
        this.move(activeTarget)
      }
    }
  }

  cancel() {
    this.controllerElement.classList.remove('active')
    this.controllerElement.style.transform = ''
    this.active = null
    this.phraseTargets.forEach((item, i) => {
      item.classList.remove('active')
    })
  }

  move(el) {
    let offset = -(el.offsetLeft + parseInt(this.controllerElement.style.left))
    this.controllerElement.style.transform = 'translateX(' + offset + 'px) translateY(-50%)'
  }

  get active() {
    const value = this.data.get('active')
    const intValue = parseInt(value)
    if (intValue.toString() === value) {
      return intValue
    }
    null
  }

  set active(value) {
    this.data.set('active', value)
    this.showCurrentBuzzword()
  }

  get controllerElement() {
    return this.context.scope.element
  }
}
